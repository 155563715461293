<template>
  <CRow>
    <CCol col="12" xl="12">
      <Search
          :dates="dates" 
          :date_type.sync="date_type" 
          :is_measures="true" 
          :enable_excel.sync="enable_excel"
          @search="searchStat"
          @save="saveMeasurement()"
      />
      <div class="row">
        <div class="col-sm-12 col-lg-12">
            <Chart 
                ref="echartComponent"
                v-for="(component, index) in device_components"
                :key="index"
                :content="component"
                :thresholds="thresholds"/>
        </div>
      </div>

      <div class="row" v-if="device_components.length == 0">
        <div class="col-sm-12 col-lg-12">
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title"><strong>{{content_name}}</strong></h5>
          </div>
          <div class="card-footer">
            <h6 class="card-text">{{message}}</h6>
          </div>
        </div>
        </div>
      </div>

      <Progress
          ref="progressDialog"
          title="확인"
      />

      <Notify ref="notifyDialog"/>

    </CCol>
  </CRow>
</template>

<script>
import HistoryMeasure from '@/views/device/HistoryMeasure';
import Search from '@/views/device/Search';
import Chart from '@/views/srds/device/Chart';
import Progress from '@/views/popups/Progress';
import { DATE } from '@/constants/constant'

export default {
  name: 'TOCHistoryMeasure',
  extends: HistoryMeasure,
  components: {
    Search,
    Chart,
    Progress
  },
  data () {
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    var from = new Date(today.setTime(today.getTime() - 7 * 86400000))
    var to = new Date(now.setTime(now.getTime() + 86400000))

    // from = new Date('2019-01-01'); // test

    return {
      isAdmin: this.$store.state.auth.is_admin,
      date_type: DATE.TYPE.HOURLY,
      stat_data: [],
      dates: [from, to],
      enable_excel: false,
      device_components: [],
      dashboard_components: [],
      thresholds: [],
      message: '조회중입니다.',
      content_name: ''
    }
  },  
}
</script>