<template>
      <CCard>
        <CCardBody>
          <div class= "row" id="deviceContainer">
            <div class="col-sm-12 col-lg-7">
              <History ref="historyView" :device_guid="this.$route.params.id" :device.sync="device"/>
            </div>  
            <div class="col-sm-12 col-lg-5">
              <div class="row">
                <!--제품정보 table - 제품번호/설치정보/업로드주기-->
                <div class="col-sm-12 col-lg-12" style="border:1px solid #eee;border-radius:1%; right:2.5%;padding:3% 6% 1% 6%">   
                  <table class="table" v-bind="getDeviceData()">
                    <tbody>
                      <tr style="margin-left:auto;margin-right:auto;">
                        <th style="border-top:none;text-align: center;" colspan="2">
                          <span style="color:#00A8FF;font-size:1.4em;">{{ site.name }} / {{ device.name }}</span>
                        </th>
                      </tr>
                      <tr>
                        <th style="border-top:none" scope="col">제품번호</th>
                        <td style="border-top:none" scope="col">{{deviceGuid}}</td>
                      </tr>
                      <tr>
                        <th scope="col">설치주소</th>
                        <td scope="col">{{deviceAddr}}</td>
                      </tr>
                      <tr>
                        <th scope="col">업로드 주기</th>
                        <td scope="col">{{cronStringData}}</td>
                      </tr>
                      <tr>
                        <th scope="col">최근 측정일시</th>
                        <td scope="col">{{sensorUpdated}} {{ sensorUpdatedTime }}</td>
                      </tr>
                      <tr>
                        <th scope="col">최근 측정 값</th>
                        <td scope="col">{{sensorValue}} ppm</td>
                      </tr>
                      <tr>
                        <th scope="col"> 센서정보관리</th>
                        <td scope="col">
                          <CButton color="warning" class="float-left mr-1" @click="openDialog('sensor')">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 130 512 312"><path fill="#808080" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg> 수정
                          </CButton>
                          <CButton color="primary" class="float-left mr-1" v-show="service_type == 'toc'" @click="openDialog('toc')"> 알람 기준 설정 </CButton>   
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>            
          </div>
        </CCardBody>
      <Confirm
        ref="confirmDialog"
        title="확인"
        @hide="hideModal"
        color="warning"
      />
      <DeviceInfo
        ref="deviceInfo"
        :isAdmin="is_admin"
        :isMobile="is_mobile"
        :device.sync="device"
        @hide="hideModal"
        @update="onUpdated"
      />

      <TOCThreshold
        ref="tocthresholdDialog"
        :isAdmin="is_admin"
        :isMobile="is_mobile"
        :device.sync="device"
        @update="onUpdated"
      />
      </CCard> 

</template>
<script>
import loopback from '@/services/loopback';

import moment from 'moment';
import utils from '@/services/utils';
import Device from '@/views/srds/device/Device'
import DeviceInfo from '@/views/srds/device/DeviceInfo'

import TOCThreshold from '@/views/toc/device/TOCThreshold'

import History from '@/views/toc/device/History'

const DEFAULT_SERVICE_TYPE = 'sis'

export default {
  name: 'DeviceExt',
  extends: Device,
  components: {
    DeviceInfo,
    History,
    TOCThreshold
  },
  data(){
    return{
      deviceGuid: '',
      deviceAddr: '',
      installationDate : '', 
      cronStringData : '',
      deviceStatus : '', 
      sensorUpdated : '',
      sensorUpdatedTime : '',
      sensorValue: '',
    }
  },
  mounted(){
    this.$refs.historyView.$refs.historyMeasure.searchStat(this.$refs.historyView.$refs.historyMeasure.dates, this.$refs.historyView.$refs.historyMeasure.date_type)
  },
  methods: {
    getDeviceData(){
      if(_.isUndefined(this.device.sensor))
        return;
      this.deviceGuid = _.get(this.device, "deviceid") + this.device.guid;
      this.deviceAddr = _.get(this.device, "address", "");
      this.installationDate= moment(_.get(this.device, "install_date", "")).format("YYYY-MM-DD HH:mm:ss");
      this.cronStringData = this.getCronStringValue(); 
      this.deviceStatus = _.get(this.device, "status");
      this.sensorUpdated = moment(this.device.sensor.created_at).format('YYYY.MM.DD')
      this.sensorUpdatedTime = moment(this.device.sensor.created_at).format('HH시 mm분')
      this.sensorValue = this.getSensorData()
    }, 
    hideModal(event) {
      console.log("hideModal:: event : ", event)
      if (event.name === 'Uploader' && event.which === 'update') {
        return
      } 
      else if (event.data !== 'confirm' || event.which !== 'reset') {
        return; // cancel
      }
    },  
    openDialog(which) {
      var info = {
        device: this.device,
        site: this.site,
        service_type: DEFAULT_SERVICE_TYPE,
        thresholds: _.cloneDeep(_.filter(this.thresholds, o => o.service_type === DEFAULT_SERVICE_TYPE))
      }
      switch (which) {
        case 'sensor':
          this.$refs.deviceInfo.show(_.isEmpty(this.sensor) ? [] : this.sensor);
          break;
        case 'toc':
          info.type = 48; info.id = 1;
          this.$refs.tocthresholdDialog.show(info, 'TOC');
          break;
        case 'battery':
          info.type = 1; info.id = 1;
          this.$refs.tocthresholdDialog.show(info, '배터리');
          break;  
      }
    },
    getSensorData() {
      var datas = this.device;
      var result = '';
      if (_.isUndefined(datas.sensor)){
          console.log("isUndefined");
          return;
      }

      datas.sensor.data.forEach(function(e){
        if(e.type === 48 || e.type === 1)
          result = e.value;
      })

      return result;
    }
  }
}
</script>

<style lang="scss" scoped>
#deviceContainer{ 
  font-family: 'Noto Sans KR', sans-serif;
}
.title {
    text-align: center;
    background-color: #0e1575;
    color: white;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
}
.carousel-inner img {
    width: 100%;
    /* height: 340px;
    max-width: 450px; */
    
}
#custCarousel .carousel-indicators {
    position: static;
    margin-top:0.5px;
    margin-left:1px;
    
}

#custCarousel .carousel-indicators > li {
  width:120px;
  
}

#custCarousel .carousel-indicators li img {
    display: block;
    opacity: 0.75;
}

#custCarousel .carousel-indicators li.active img {
  opacity: 1;
  
}

#custCarousel .carousel-indicators li:hover img {
  opacity: 0.95;
}

#custCarousel .carousel-control-prev {
  width: 54px;
}

#custCarousel .carousel-control-prev .fa-chevron-left {
  position: absolute;
  top: 30%;
}

#custCarousel .carousel-control-next {
  width: 54px;
}

#custCarousel .carousel-control-next .fa-chevron-right {
  position: absolute;
  top: 30%;
}
// custCarousel
#custCarousel2 .carousel-indicators {
    position: static;
    margin-top:0.5px;
    margin-left:1px;
    
}

#custCarousel2 .carousel-indicators > li {
  width:120px;
  
}

#custCarousel2 .carousel-indicators li img {
    display: block;
    opacity: 0.75;
}

#custCarousel2 .carousel-indicators li.active img {
  opacity: 1;
  
}

#custCarousel2 .carousel-indicators li:hover img {
  opacity: 0.95;
}

#custCarousel2 .carousel-control-prev {
  width: 54px;
}

#custCarousel2 .carousel-control-prev .fa-chevron-left {
  position: absolute;
  top: 30%;
}

#custCarousel2 .carousel-control-next {
  width: 54px;
}

#custCarousel2 .carousel-control-next .fa-chevron-right {
  position: absolute;
  top: 30%;
}

.carousel-item img{
  width:86%;
}
.carousel-indicators-container {
  height: 130px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 86%;
}
.carousel-indicators-container::-webkit-scrollbar{
  height: 10px;

}
.carousel-indicators-container::-webkit-scrollbar-thumb {
  height: 30%; /* 스크롤바의 길이 */
  background: #00000064; /* 스크롤바의 색상 */
  border-radius: 10px;


}

.carousel-indicators-container::-webkit-scrollbar-track {
  background: #eee  /*스크롤바 뒷 배경 색상*/
}


.carousel-indicators-wrapper {
  display: flex;

}

#img-thumbnails{
  height: 86px;
}


#carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
    color: #fff;
    text-align: center;
    background-color: cornflowerblue;
    border-radius: 2%;
}

#main-img-caption {
  position: absolute;
  bottom: 0; /* 이미지 상단 시 top : 0 */
  left: 50%;
  transform: translateX(-50%);
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 2%;
  width: 80%;
  max-width: 500px;
  box-sizing: border-box;
  margin: auto;
}

#thumbnail-caption{
  bottom: 10%;
  position: sticky;

}

#thumb-content{
  font-size: 15px;
}

.image-container {
  position: relative;
}

.caption-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4); 
  color: #fff; 
  padding: 10px;
  box-sizing: border-box;
}

.caption {
  margin: 0;
}


#thumb_caption{
  padding: 3%;
  border-radius: 10%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  position: relative;
  left: 415%;
  top: 30px;
  z-index: 3;
}

#thumb_caption:hover {
  opacity: 0.95;
}

#float-btn{
  width: 126px;
}

#float-btn-battery {
  width: 134px;
  font-size: 14px;
}

.infoBox2 {
  margin-top: 2%;
  height: 110px;
}

.infoBox3 {
  height: 90px;
}

@media (max-width: 768px) {
  #custCarousel .carousel-control-prev {
    width: 40px;
  }

  #custCarousel .carousel-control-prev .fa-chevron-left {
    position: absolute;
    top: 22%;
  }

  #custCarousel .carousel-control-next {
    width: 40px;
  }

  #custCarousel .carousel-control-next .fa-chevron-right {
    position: absolute;
    top: 22%;
  }

  .time {
    font-size: 12px;
  }

  #main-img-caption {
    position: absolute;
    bottom: 0; /* 이미지 상단 시 top : 0 */
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 4px;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 2%;
    width: 70%;
    max-width: 500px;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  #custCarousel2 .carousel-control-prev {
    width: 40px;
  }

  #custCarousel2 .carousel-control-prev .fa-chevron-left {
    position: absolute;
    top: 22%;
  }

  #custCarousel2 .carousel-control-next {
    width: 40px;
  }

  #custCarousel2 .carousel-control-next .fa-chevron-right {
    position: absolute;
    top: 22%;
  }

  .time {
    font-size: 12px;
  }

  .infoBox2 {
    height: 200px;
  }

  .infoBox3 {
    height: 120px;
  }

  #main-img-caption {
    position: absolute;
    bottom: 0; /* 이미지 상단 시 top : 0 */
    left: 50%;
    transform: translateX(-50%);
    padding-top: 4px;
    padding-bottom: 4px;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 2%;
    width: 70%;
    max-width: 500px;
    box-sizing: border-box;
  }

}

</style>