<template>
  <CCard>
      <CCol col="12" xl="12">
        <ul class="nav nav-tabs nav-pills" style="padding-top: 15px;">
          <li class="nav-item">
            <a tab class="nav-link" @click.prevent="setActive('tab3')" :class="{ active: isActive('tab3') }" href='#tab3'>측정 이력</a>
          </li>      
        </ul>
      </CCol>   
      <div class="tab-content" style="padding: 15px;">
        <div class="tab-pane" id='tab3' :class="{ 'active show': isActive('tab3') }">
            <Device 
              :device_guid="this.$route.params.id" 
              :device.sync="device" 
              @update="onUpdated"
              @reset="onResetSensor"/>
        </div>
      </div>     
    </CCard>
</template>

<script>
import DeviceManage from '@/views/device/DeviceManage'
import Device from '@/views/toc/device/Device'
import History from '@/views/toc/device/History'

export default {
  name: 'TOCDeviceManage',
  extends: DeviceManage,
  components: {
    Device,
    History
  },
  data () {
    return {
      activeItem: 'tab3',
    }
  },
  methods: {
    isActive (menuItem) {
      if(this.activeItem == 'tab4'){
        this.$refs.historyView.$refs.historyMeasure.searchStat(this.$refs.historyView.$refs.historyMeasure.dates, this.$refs.historyView.$refs.historyMeasure.date_type)
        return this.activeItem === menuItem
      }
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
  }
}
</script>